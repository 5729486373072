import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Link from '../components/CustomLink';
// import avatar from '../img/my-avatar.jpeg';
import whale from '../img/whale-watching.jpg';
import myself from '../img/myself.jpg';
import glaciers from '../img/glaciers.jpg';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { TurSystemIcon } from '../img/icons';

export const IndexPageTemplate = ({ tech, db }) => (
  <section className="p-6 md:p-4 md:pt-8 md:text-left">
    <div className="flex items-center text-left mt-6 mb-6 md:mt-20">
      <TurSystemIcon className="fill-current text-primary h-16 w-16 md:h-20 md:w-20" />

      <h1 className="ml-5 text-2xl font-bold text-primary md:text-4xl leading-tight">
        Hi, my name is <br />
        Sandor Turanszky
      </h1>
    </div>
    <p className="mb-5">I am a software engineer and I build web projects of any kind.</p>
    <div className="mb-5">
      <div className="mb-1">Most of the things I can do with</div>{' '}
      <ul className="flex flex-row flex-wrap font-mono text-sm">
        {tech.map((item, index) => (
          <li
            key={index}
            className="rounded border border-surface-01 bg-surface-01 p-1 pl-2 pr-2 mr-1 mb-2"
          >
            {item}
          </li>
        ))}{' '}
        <li className="p-1 pl-2 pr-2 mr-1 mb-2">and</li>{' '}
        <li className="rounded border border-surface-01 bg-surface-01 p-1 pl-2 pr-2 mr-1 mb-2">
          {db}
        </li>
      </ul>
    </div>
    <p className="mb-5">
      Here is my latest side project{' '}
      <Link to="https://www.comparecourses.dev/">CompareCourses.dev</Link> where I search the
      endless internet for awesome programming courses, review them to see if they are worth taking
      and package into learning paths both for beginners and advanced developers.
    </p>
    <p className="mb-5">
      Recently I explored <Link to="https://upstash.com/">Serverless Redis</Link> and built a <Link to="https://techforitrecruiters.com/questions">Q&A</Link> project with it. 
      As always, I documented my learning journey and posted on <Link to="https://dev.to/sandorturanszky">DEV.to</Link>.
    </p>    
    <p className="mb-5">
      Another serverless database I explored is <Link to="https://fauna.com/">FaunaDB</Link>. I developed a proof of concept described in a series of
      articles on <Link to="https://dev.to/sandorturanszky">DEV.to</Link> about how its features can
      help developers to quick start an MVP. I liked the built-in GraphQL API in particular.
    </p>
    <p className="mb-5">
      I think open-source is important and here is one of my{' '}
      <Link to="https://github.com/sandorTuranszky/production-ready-expressjs-server">
        projects
      </Link>{' '}
      on GitHub.
    </p>
    <p className="mb-5">
      In my free time, I am consulting recruiters on technology. You can read my LinkedIn posts
      under{' '}
      <Link to="https://www.linkedin.com/feed/hashtag/?keywords=%23techforitrecruiters">
        #techforitrecruiters
      </Link>{' '}
      hashtag. More information about the training is available on{' '}
      <Link to="https://techforitrecruiters.com">www.techforitrecruiters.com</Link>
    </p>
    <p className="mb-5">
      <Link to="https://www.linkedin.com/in/sandor-turanszky-ab716447">LinkedIn</Link> is a good way
      to learn more about me or send me a message.
    </p>
    <p className="mb-5">
      As for my interests, I am fascinated by our planet's glaciers and ice caps and I dream to do{' '}
      whale watching one day.
    </p>

    <div className="flex mb-5">
      <PreviewCompatibleImage
        imageInfo={{
          image: glaciers,
          alt: 'Glaciers, simply put, are huge rivers of ice',
        }}
        style={{ overflow: 'initial' }}
        className="h-24 w-auto md:h-32 shadow"
      />

      <PreviewCompatibleImage
        imageInfo={{
          image: whale,
          alt: 'Whales are beautiful animals, they have a big heart, literally and figuratively',
        }}
        style={{ overflow: 'initial' }}
        className="h-24 w-auto md:h-32 ml-2 shadow"
      />

      <PreviewCompatibleImage
        imageInfo={{
          image: myself,
          alt: 'Myself',
        }}
        style={{ overflow: 'initial' }}
        className="h-24 w-auto md:h-32 ml-2 shadow"
      />
    </div>
  </section>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  tech: PropTypes.array,
  db: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const tech = [
    'JavaScript',
    'TypeScript',
    'React',
    'Vue',
    'Gatsby',
    'TailwindCSS',
    'Node',
    'Express',
    'Next',
    'GraphQL',
    'Redis',
    'FaunaDB',
    'MongoDB',
  ];

  const db = tech.pop();

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        tech={tech}
        db={db}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
      }
    }
  }
`;
